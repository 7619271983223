import { navigate } from 'gatsby'
import React from 'react'
import { useQuery } from '@apollo/client'
import { CacheUser, PurchaseRequest, ResponseType, TableColumnType, TableRowType } from '@types'
import { EmptyTable, MobileGolfClubCard, Pagination, StatusIcon } from '@elements'
import { ActionsPopupMenu, Table } from '@components'
import { useAlert } from 'react-alert'
import PopupComponent from 'reactjs-popup'
import { AccountLayout, MobileAccountLayout } from '@layouts'
import { useGraphqlPurchaseRequest, usePagination, useWindowSize } from '@hooks'
import { purchaseRequest, user } from '@graphql'
import Dots from '@images/three-vertical-dots.svg'
import { addDays, get12HourFromDate, getGolfClubInfoByCategory } from '@utils'
import ArrowIcon from '@images/link-arrow.svg'
import './my-offer-book.scss'

interface AccountMyOfferBookProps {}

const formatDateTimeString = (dateString: string) => {
  const date = new Date(dateString)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  const formattedDate = `${month}/${day}/${year}`
  const formattedTime = get12HourFromDate(date)
  return {
    formattedDate,
    formattedTime
  }
}

const AccountMyOfferBook: React.FC<AccountMyOfferBookProps> = () => {
  const { deletePurchaseRequest, extendPurchaseRequest } = useGraphqlPurchaseRequest()
  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])
  const { data } = useQuery<ResponseType<CacheUser>>(user.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })
  const currentUser = data && data.res

  if (!currentUser) navigate('/home/')
  if (!currentUser) return null

  const { content, pagination, loading, fetchMore } = usePagination<PurchaseRequest>(
    purchaseRequest.FetchPaginatedPurchaseRequests,
    {},
    {},
    1,
    6
  )

  const alert = useAlert()

  const handleExtend = async (purchaseRequest: PurchaseRequest) => {
    const { id } = purchaseRequest

    const newBuyEndDate = addDays(new Date(), 10)
    const date = new Date(newBuyEndDate).toISOString().split('T')[0]

    if (purchaseRequest) {
      await extendPurchaseRequest(id, String(newBuyEndDate))
      alert.show(`Purchase request extended by 10 days!`, {
        type: 'success'
      })
    }
  }

  const handleDelete = (purchaseRequest: PurchaseRequest) => {
    const { id } = purchaseRequest
    deletePurchaseRequest(id)
  }

  const renderControls = (_: any, PurchaseRequest: TableRowType) => {
    const isExpired = PurchaseRequest.status === 'EXPIRED'
    const golfClubStatusMenuWithExtend = [
      {
        label: 'Extend',
        value: '',
        icon: '/assets/images/reload.svg',
        onClick: () => handleExtend(PurchaseRequest as PurchaseRequest)
      },
      {
        label: 'Delete',
        value: '',
        icon: '/assets/images/actions-delete.svg',
        onClick: () => handleDelete(PurchaseRequest as PurchaseRequest)
      }
    ]
    const golfClubStatusMenuWithoutExtend = [
      {
        label: 'Delete',
        value: '',
        icon: '/assets/images/actions-delete.svg',
        onClick: () => handleDelete(PurchaseRequest as PurchaseRequest)
      }
    ]
    return <ActionsPopupMenu options={isExpired ? golfClubStatusMenuWithExtend : golfClubStatusMenuWithoutExtend} />
  }
  const renderStatus = (status: string) => {
    switch (status) {
      case 'OPEN':
        return <StatusIcon status={'Active'} />
      case 'CLOSED':
        return <StatusIcon status={'Purchased'} />
      case 'EXPIRED':
        return <StatusIcon status={'Expired'} />
      default:
        return <StatusIcon status={'Expired'} />
    }
  }

  const renderMobilePopup = (purchaseRequest: PurchaseRequest) => {
    const status = purchaseRequest.status
    const isExpired = status === 'EXPIRED'
    if (status === 'CLOSED') {
      return null
    }

    return (
      <PopupComponent
        trigger={
          <div className={'profile-payment-header-cards-item-view-actions'}>
            <Dots />
          </div>
        }
        modal
        overlayStyle={{ background: 'rgba(82, 82, 82, 0.61)' }}
        contentStyle={{ background: 'transparent', width: '100%', marginBottom: '0' }}
      >
        {(close: () => void) => {
          return (
            <div style={{ width: '300px', margin: '0 auto', fontSize: '18px' }}>
              <div
                style={{
                  background: '#ffffff',
                  borderRadius: '14px',
                  boxShadow: '2px 4px 30px rgba(0, 0, 0, 0.14)'
                }}
              >
                {isExpired && (
                  <div
                    style={{
                      padding: '19px 50px',
                      textAlign: 'center',
                      color: '#0C0C0C',
                      cursor: 'pointer'
                    }}
                    onClick={() => handleExtend(purchaseRequest)}
                  >
                    Extend
                  </div>
                )}
                <div
                  style={{
                    padding: '19px 50px',
                    textAlign: 'center',
                    color: '#F9655B',
                    borderTop: isExpired ? '1px solid #EFEFF9' : '',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleDelete(purchaseRequest)}
                >
                  Delete
                </div>
              </div>
              <div
                style={{
                  background: '#ffffff',
                  padding: '19px 50px',
                  marginTop: '10px',
                  borderRadius: '14px',
                  boxShadow: '2px 4px 30px rgba(0, 0, 0, 0.14)',
                  textAlign: 'center',
                  cursor: 'pointer'
                }}
                onClick={close}
              >
                Cancel
              </div>
            </div>
          )
        }}
      </PopupComponent>
    )
  }

  const renderActionStatus = (purchaseRequest: PurchaseRequest) => {
    const status = purchaseRequest.status
    return (
      <div className={'account-my-offer-book-table-card-status'}>
        {renderStatus(status)}
        {renderMobilePopup(purchaseRequest)}
      </div>
    )
  }

  // we need use modify array this
  const columns: TableColumnType[] = [
    {
      title: 'Item',
      key: 'item',
      render(_, row) {
        const title = row?.golfClubType?.golfClubModel?.title || row?.golfEquipmentType?.golfEquipmentModel?.title
        return <>{title || '-'}</>
      }
    },
    {
      title: 'Brand',
      key: 'brand',
      render(_, row) {
        const brand = row?.golfClubType?.brand.title || row?.golfEquipmentType?.brand.title
        return <>{brand || '-'}</>
      }
    },
    {
      title: 'Category',
      key: 'golfClubType.category.title',
      render(_, row) {
        const category = row?.golfClubType?.category.title || row?.golfEquipmentType?.category.title
        return <>{category || '-'}</>
      }
    },
    {
      title: 'OPTION',
      key: 'golfClubType.option',
      render(_, row) {
        const option = row?.golfClubType?.option || row?.golfEquipmentType?.option
        return <>{option || '-'}</>
      }
    },
    {
      title: 'Offer Date',
      key: 'insertedAt',
      render(insertedDate: string) {
        const { formattedDate, formattedTime } = formatDateTimeString(insertedDate)
        return (
          <>
            {formattedDate}
            <br />
            {formattedTime}
          </>
        )
      }
    },
    {
      title: 'Expire Date',
      key: 'expiresAt',
      render(expireDate: string) {
        const { formattedDate, formattedTime } = formatDateTimeString(expireDate)
        return (
          <>
            {formattedDate}
            <br />
            {formattedTime}
          </>
        )
      }
    },
    {
      title: 'Price',
      key: 'itemPrice',
      render(price: number) {
        return <>{'$' + price}</>
      }
    },
    {
      title: 'Status',
      key: 'status',
      render: renderStatus
    },
    {
      title: '',
      key: 'menu',
      render: renderControls
    }
  ]

  const renderTable = () => {
    if (!isMobileScreen && !purchaseRequests?.length) {
      return <EmptyTable title={loading ? 'Your Offer Book' : 'Your Offer Book is empty'} />
    }

    if (!isMobileScreen)
      return (
        <Table
          columns={columns}
          rows={purchaseRequests || []}
          handleRowClick={row => navigate(`/account/my-offer-book/${row.id}/details`)}
        />
      )
  }

  const renderMobileTable = () => {
    if (isMobileScreen && !purchaseRequests?.length) {
      return <EmptyTable title={loading ? 'Your Offer Book' : 'Your Offer Book is Empty'} />
    }

    if (isMobileScreen)
      return purchaseRequests?.map(purchaseRequest => {
        const avatar =
          purchaseRequest?.golfClubType?.golfClubModel?.avatar ||
          purchaseRequest?.golfEquipmentType?.golfEquipmentModel?.avatar ||
          ''
        const title =
          purchaseRequest?.golfClubType?.golfClubModel?.title ||
          purchaseRequest?.golfEquipmentType?.golfEquipmentModel?.title ||
          ''
        const modelTitle = purchaseRequest?.golfClubType?.golfClubModel?.title || ''
        const purchaseRequestOptionType = getGolfClubInfoByCategory(
          purchaseRequest?.golfClubType?.golfClubModel?.category
        )
        const { formattedDate: formattedUpdatedDate, formattedTime: formattedUpdatedTime } = formatDateTimeString(
          purchaseRequest?.insertedAt
        )
        const { formattedDate: formattedExpiredDate, formattedTime: formattedExpiredTime } = formatDateTimeString(
          purchaseRequest?.expiresAt
        )
        const data = [
          {
            label: 'Brand',
            value:
              purchaseRequest?.golfClubType?.brand?.title || purchaseRequest?.golfEquipmentType?.brand?.title || '-'
          },
          {
            label: 'Category',
            value:
              purchaseRequest?.golfClubType?.category?.title ||
              purchaseRequest?.golfEquipmentType?.category?.title ||
              '-'
          },
          {
            label: purchaseRequestOptionType.title,
            value: purchaseRequest?.golfClubType?.option
              ? purchaseRequest?.golfClubType?.option + purchaseRequestOptionType.options.symbol
              : '-'
          },
          {
            label: 'Offer Date',
            value: `${formattedUpdatedTime} ${formattedUpdatedDate}`
          },
          {
            label: 'Expire Date',
            value: `${formattedExpiredTime} ${formattedExpiredDate}`
          },
          {
            label: 'Price',
            value: purchaseRequest?.itemPrice ? `$${purchaseRequest.itemPrice}` : '-'
          }
        ]
        return (
          <MobileGolfClubCard
            key={purchaseRequest.id}
            avatar={avatar}
            title={`${modelTitle} ${title} `}
            // subtitle={renderStatus(purchaseRequest.status)}
            subtitle={renderActionStatus(purchaseRequest)}
            data={data}
            footer={
              <div
                className={'account-my-offer-book-mobile-card-more-button'}
                onClick={() => navigate(`/account/my-offer-book/${purchaseRequest.id}/details`)}
              >
                <strong>More</strong>
                <ArrowIcon />
              </div>
            }
          />
        )
      })
  }

  const Layout = isMobileScreen ? MobileAccountLayout : AccountLayout
  const purchaseRequests = content
  return (
    <Layout pageClassName={'account-my-offer-book'} title={'My Offer Book'}>
      {!isMobileScreen && <h6 className={'account-layout-page-title'}>MY OFFER BOOK</h6>}
      <div className={'account-my-offer-book-table'}>
        {renderMobileTable()}

        {renderTable()}
        <Pagination {...pagination} onClick={page => fetchMore({}, page)} />
      </div>
    </Layout>
  )
}

export default AccountMyOfferBook
